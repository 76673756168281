import type { ReactNode } from "react";
import { Children } from "react";
import clsx from "clsx";
import type { FontWeight } from "~/types/tailwind";

function fixLineBreaks(val: string) {
	return val?.replace("\\n", "\n");
}

function trademark(text: string) {
	if (!text.includes("®")) {
		return text;
	}
	return (
		<span
			dangerouslySetInnerHTML={{
				__html: text?.replace(/®/g, "<sup>®</sup>"),
			}}
		/>
	);
}

export type TitleProps = {
	as?: React.ElementType;
	className?: string;
	color?:
		| "primary"
		| "secondary"
		| "blue"
		| "yellow"
		| "green"
		| "tagline"
		| "navigation"
		| "inverse"
		| "current"
		| "warning"
		| "success"
		| "feature"
		| "theme-primary"
		| "theme-secondary";
	id?: string;
	children?: ReactNode;
	dangerouslySetInnerHTML?: {
		__html: string;
	};
	whiteSpacePreLine?: boolean;
};

// https://www.figma.com/file/lGRsfindrD008bHMD3EKhG/Design-System-Marketing-Website-(WIP)?node-id=1%3A63
export const sizes = {
	"heading-1-article":
		"font-heading text-3xl md:text-4xl leading-[1.25] -tracking-[0.02em]",
	"heading-1":
		"font-heading text-3xl md:text-5xl leading-[1.25] -tracking-[0.02em]",
	"heading-2":
		"font-heading text-2xl md:text-3xl leading-[1.375] -tracking-[0.01em]",
	"heading-3": "font-heading text-xl md:text-2xl leading-[1.375]",
	"heading-4": "font-heading text-lg md:text-xl leading-[1.375]",
	"heading-5": "font-heading text-md md:text-lg leading-[1.375]",
	"heading-6": "font-heading text-base md:text-md leading-[1.5]",
	"body-large":
		"font-body text-md md:text-lg leading-[1.375] tracking-normal",
	"body-default": "font-body text-sm md:text-base leading-[1.5]",
	"body-small": "font-body text-xs md:text-sm leading-[1.5]",
	"body-xsmall": "font-body text-xs leading-[1.5] tracking-[0.04em]",
	overline: "font-body text-xs leading-[1.5] tracking-[0.1em] uppercase",
	button: "font-body text-sm md:text-base leading-[1.5]",
	"button-small": "font-body text-xs md:text-sm leading-[1.5]",
};

const sizeToTag = {
	"heading-1-article": "h1",
	"heading-1": "h1",
	"heading-2": "h2",
	"heading-3": "h3",
	"heading-4": "h4",
	"heading-5": "h5",
	"heading-6": "h6",
	"body-large": "p",
	"body-default": "p",
	"body-small": "p",
	"body-xsmall": "p",
	overline: "p",
	button: "span",
	"button-small": "span",
};

const textColors = {
	primary: "text-primary",
	blue: "text-blue-100",
	yellow: "text-yellow-100",
	green: "text-green-100",
	secondary: "text-secondary",
	tagline: "text-tagline",
	navigation: "text-navigation",
	current: "text-current",
	inverse: "text-inverse",
	warning: "text-warning",
	success: "text-success",
	feature: "text-feature",
	"theme-primary": "text-theme-primary",
	"theme-secondary": "text-theme-secondary",
};

export function Title({
	size = "body-default",
	as,
	className,
	fontWeight,
	color = "primary",
	whiteSpacePreLine = true,
	...rest
}: TitleProps & { size: keyof typeof sizes; fontWeight?: FontWeight }) {
	const Tag = as ?? sizeToTag[size];

	const isHeading = typeof Tag === "string" && Tag.startsWith("h");

	// fix escaped line breaks
	if (typeof rest.children === "string") {
		if (isHeading) {
			rest.children = trademark(fixLineBreaks(rest.children));
		} else {
			rest.children = fixLineBreaks(rest.children);
		}
	}

	if (Array.isArray(rest.children)) {
		rest.children = Children.map(rest.children, (node) => {
			if (typeof node === "string") {
				if (isHeading) {
					return trademark(fixLineBreaks(node));
				} else {
					return fixLineBreaks(node);
				}
			}

			return node;
		});
	}

	if (rest.dangerouslySetInnerHTML) {
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions, no-unused-expressions
		rest.dangerouslySetInnerHTML.__html ==
			fixLineBreaks(rest.dangerouslySetInnerHTML.__html);
	}

	return (
		<Tag
			className={clsx(
				sizes[size],
				fontWeight,
				textColors[color],
				className,
				{
					"whitespace-pre-line": whiteSpacePreLine,
				}
			)}
			{...rest}
		/>
	);
}

function H1(props: TitleProps) {
	return (
		<Title
			{...props}
			size="heading-1"
			fontWeight="font-semibold"
			color="primary"
		/>
	);
}

function H2(props: TitleProps) {
	return (
		<Title
			{...props}
			size="heading-2"
			fontWeight="font-semibold"
			color="primary"
		/>
	);
}

function H3(props: TitleProps) {
	return (
		<Title
			{...props}
			size="heading-3"
			fontWeight="font-semibold"
			color="primary"
		/>
	);
}

function H4(props: TitleProps) {
	return (
		<Title
			{...props}
			size="heading-4"
			fontWeight="font-semibold"
			color="primary"
		/>
	);
}

function H5(props: TitleProps) {
	return (
		<Title
			{...props}
			size="heading-5"
			fontWeight="font-medium"
			color="primary"
		/>
	);
}

function H6(props: TitleProps) {
	return (
		<Title
			{...props}
			size="heading-6"
			fontWeight="font-medium"
			color="primary"
		/>
	);
}

const fontWeights: Record<string, FontWeight> = {
	overline: "font-medium",
	button: "font-medium",
	"button-big": "font-medium",
	"button-small": "font-medium",
};

function Paragraph({
	size = "body-default",
	fontWeight,
	...props
}: TitleProps & { size?: keyof typeof sizes; fontWeight?: FontWeight }) {
	const defaultFontWeight = fontWeights[size] || "font-normal";

	return (
		<Title
			{...props}
			fontWeight={fontWeight ? fontWeight : defaultFontWeight}
			size={size}
		/>
	);
}

function Blockquote({
	size = "body-large",
	as = "blockquote",
	...props
}: TitleProps & { size?: keyof typeof sizes }) {
	return <Title {...props} size={size} as={as} />;
}

export { H1, H2, H3, H4, H5, H6, Paragraph, Blockquote };
